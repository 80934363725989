/**
 * List of channels and services that are to be displayed in the sales flow
 * This should be moved to Strim.Admin so that product entitlements as well as
 * channel id's and logos have a single source of truth.
 */

export interface ChannelOrService {
  title: string;
  logoName: string;
  description?: string;
  type: 'channel' | 'service';
}

// Groups of channels
const LittChannels: ChannelOrService[] = [
  {
    title: 'TV 2 Direkte',
    logoName: 'Tv2DirekteV2',
    type: 'channel',
  },
  {
    title: 'TV 2 Nyheter',
    logoName: 'Tv2NyheterV2',
    type: 'channel',
  },
  {
    title: 'TVNorge',
    logoName: 'TvNorge',
    type: 'channel',
  },
  {
    title: 'REX',
    logoName: 'Rex',
    type: 'channel',
  },
  {
    title: 'FEM',
    logoName: 'Fem',
    type: 'channel',
  },
  {
    title: 'TV3',
    logoName: 'Tv3',
    type: 'channel',
  },
  {
    title: 'TV3+',
    logoName: 'TV3Pluss',
    type: 'channel',
  },
  {
    title: 'BBC News',
    logoName: 'BBCNews',
    type: 'channel',
  },
  {
    title: 'CNN',
    logoName: 'CNN',
    type: 'channel',
  },
];
const StrimMindreChannels: ChannelOrService[] = [
  {
    title: 'TV 2 Direkte',
    logoName: 'Tv2DirekteV2',
    type: 'channel',
  },
  {
    title: 'TV 2 Nyheter',
    logoName: 'Tv2NyheterV2',
    type: 'channel',
  },
  {
    title: 'TV 2 Zebra',
    logoName: 'Tv2ZebraV4',
    type: 'channel',
  },
  {
    title: 'TV 2 Livsstil',
    logoName: 'Tv2LivsstilV4',
    type: 'channel',
  },
  {
    title: 'TVNorge',
    logoName: 'TvNorge',
    type: 'channel',
  },
  {
    title: 'REX',
    logoName: 'Rex',
    type: 'channel',
  },
  {
    title: 'FEM',
    logoName: 'Fem',
    type: 'channel',
  },
  {
    title: 'VOX',
    logoName: 'Vox',
    type: 'channel',
  },
  {
    title: 'TV3',
    logoName: 'Tv3',
    type: 'channel',
  },
  {
    title: 'TV3+',
    logoName: 'TV3Pluss',
    type: 'channel',
  },

  {
    title: 'CNN',
    logoName: 'CNN',
    type: 'channel',
  },
  {
    title: 'BBC News',
    logoName: 'BBCNews',
    type: 'channel',
  },
  {
    title: 'TV6',
    logoName: 'Tv6',
    type: 'channel',
  },
];
const MyeChannels: ChannelOrService[] = [
  {
    title: 'TV 2 Direkte',
    logoName: 'Tv2DirekteV2',
    type: 'channel',
  },
  {
    title: 'TV 2 Nyheter',
    logoName: 'Tv2NyheterV2',
    type: 'channel',
  },
  {
    title: 'TV2 Zebra',
    logoName: 'Tv2ZebraV4',
    type: 'channel',
  },
  {
    title: 'TV2 Livsstil',
    logoName: 'Tv2LivsstilV4',
    type: 'channel',
  },
  {
    title: 'TVNorge',
    logoName: 'TvNorge',
    type: 'channel',
  },
  {
    title: 'FEM',
    logoName: 'Fem',
    type: 'channel',
  },
  {
    title: 'REX',
    logoName: 'Rex',
    type: 'channel',
  },
  {
    title: 'VOX',
    logoName: 'Vox',
    type: 'channel',
  },
  {
    title: 'TV3',
    logoName: 'Tv3',
    type: 'channel',
  },
  {
    title: 'TV3+',
    logoName: 'TV3Pluss',
    type: 'channel',
  },
  {
    title: 'BBC News',
    logoName: 'BBCNews',
    type: 'channel',
  },
  {
    title: 'CNN',
    logoName: 'CNN',
    type: 'channel',
  },
  {
    title: 'TV6',
    logoName: 'Tv6',
    type: 'channel',
  },
];
const MestChannels: ChannelOrService[] = [
  ...MyeChannels,
  {
    title: 'TLC',
    logoName: 'TLC',
    type: 'channel',
  },
  {
    title: 'Animal Planet',
    logoName: 'AnimalPlanet',
    type: 'channel',
  },
  {
    title: 'Investigation Discovery',
    logoName: 'InvestigationDiscovery',
    type: 'channel',
  },
  {
    title: 'Discovery',
    logoName: 'DiscoveryChannel',
    type: 'channel',
  },
  {
    title: 'Discovery Science',
    logoName: 'DiscoveryScience',
    type: 'channel',
  },
  {
    title: 'BBC Nordic',
    logoName: 'BBCNordic',
    type: 'channel',
  },
  {
    title: 'History Channel',
    logoName: 'History_v2',
    type: 'channel',
  },
  {
    title: 'Nat Geo',
    logoName: 'NationalGeographic',
    type: 'channel',
  },
  {
    title: 'Travel Channel',
    logoName: 'Travel',
    type: 'channel',
  },
  {
    title: 'VH1',
    logoName: 'MTV00s',
    type: 'channel',
  },
  {
    title: 'Disney Channel',
    logoName: 'DisneyChannelv2',
    type: 'channel',
  },
  {
    title: 'Nickelodeon',
    logoName: 'Nickelodeon',
    type: 'channel',
  },
  {
    title: 'NickJr',
    logoName: 'NickJr',
    type: 'channel',
  },
  {
    title: 'Food Network',
    logoName: 'FoodNetwork',
    type: 'channel',
  },
  {
    title: 'Heim TV',
    logoName: 'Heim',
    type: 'channel',
  },
  {
    title: 'Auto Motor & Sport TV',
    logoName: 'AutoMotorSportTv',
    type: 'channel',
  },
  {
    title: 'Norway Live',
    logoName: 'NorwayLive',
    type: 'channel',
  },
  {
    title: 'Naturkanal1',
    logoName: 'Naturkanal1',
    type: 'channel',
  },
];
const SportChannels: ChannelOrService[] = [
  {
    title: 'TV2 Sport 1',
    logoName: 'Tv2Sport1V3',
    type: 'channel',
  },
  {
    title: 'TV2 Sport 2',
    logoName: 'Tv2Sport2V3',
    type: 'channel',
  },
  {
    title: 'Eurosport Norge',
    logoName: 'EurosportNorgeV2',
    type: 'channel',
  },
  {
    title: 'Eurosport',
    logoName: 'Eurosport1',
    type: 'channel',
  },
  {
    title: 'Viasport +',
    logoName: 'VSportPluss',
    type: 'channel',
  },
  {
    title: 'Viasport 1',
    logoName: 'VSport1',
    type: 'channel',
  },
  {
    title: 'Viasport 2',
    logoName: 'VSport2',
    type: 'channel',
  },
  {
    title: 'Viasport 3',
    logoName: 'VSport3',
    type: 'channel',
  },
];

const MaxSportChannels: ChannelOrService[] = [
  {
    title: 'Eurosport Norge',
    logoName: 'EurosportNorgeV2',
    type: 'channel',
  },
  {
    title: 'Eurosport',
    logoName: 'Eurosport1',
    type: 'channel',
  },
];

const MerUnderholdningChannels: ChannelOrService[] = [
  {
    title: 'TLC',
    logoName: 'TLC',
    type: 'channel',
  },
  {
    title: 'Animal Planet',
    logoName: 'AnimalPlanet',
    type: 'channel',
  },
  {
    title: 'Investigation Discovery',
    logoName: 'InvestigationDiscovery',
    type: 'channel',
  },
  {
    title: 'Discovery',
    logoName: 'DiscoveryChannel',
    type: 'channel',
  },
  {
    title: 'Discovery Science',
    logoName: 'DiscoveryScience',
    type: 'channel',
  },
  {
    title: 'BBC Nordic',
    logoName: 'BBCNordic',
    type: 'channel',
  },
  {
    title: 'History Channel',
    logoName: 'History_v2',
    type: 'channel',
  },
  {
    title: 'Nat Geo',
    logoName: 'NationalGeographic',
    type: 'channel',
  },
  {
    title: 'Travel Channel',
    logoName: 'Travel',
    type: 'channel',
  },
  {
    title: 'VH1',
    logoName: 'MTV00s',
    type: 'channel',
  },
  {
    title: 'Disney Channel',
    logoName: 'DisneyChannelv2',
    type: 'channel',
  },
  {
    title: 'Nickelodeon',
    logoName: 'Nickelodeon',
    type: 'channel',
  },
  {
    title: 'NickJr',
    logoName: 'NickJr',
    type: 'channel',
  },
  {
    title: 'Food Network',
    logoName: 'FoodNetwork',
    type: 'channel',
  },
  {
    title: 'Heim TV',
    logoName: 'Heim',
    type: 'channel',
  },
  {
    title: 'Auto Motor & Sport TV',
    logoName: 'AutoMotorSportTv',
    type: 'channel',
  },
  {
    title: 'Norway Live',
    logoName: 'NorwayLive',
    type: 'channel',
  },
  {
    title: 'Naturkanal1',
    logoName: 'Naturkanal1',
    type: 'channel',
  },
];

// external services
const MaxBasic: ChannelOrService = {
  title: 'Max Basis med reklame',
  logoName: 'Max',
  description: 'Se alt innhold fra HBO, pluss norske og utenlandske favoritter fra Discovery+.',
  type: 'service',
};
const MaxStandard: ChannelOrService = {
  title: 'Max Standard',
  logoName: 'Max',
  description: 'Reklamefri strømming og 30 nedlastninger',
  type: 'service',
};
const MaxPremium: ChannelOrService = {
  title: 'Max Premium',
  logoName: 'Max',
  description: 'Reklamefri strømming, 100 nedlastninger, 4k ultra HD og Audio med Dolby Atmos',
  type: 'service',
};
const MaxSport: ChannelOrService = {
  title: 'Max Sport',
  logoName: 'Max',
  type: 'service',
};
const TV2Basic: ChannelOrService = {
  title: 'TV 2 Play Basis (med reklame)',
  logoName: 'Tv2PlayV3',
  description: 'Se filmer, serier, nyheter og stort utvalg av TV 2 Direkte sine programmer.',
  type: 'service',
};
const TV2Standard: ChannelOrService = {
  title: 'TV 2 Play Film og serier',
  logoName: 'Tv2PlayV3',
  description: 'Se eksklusivt innhold, filmer, serier og TV 2 Direkte sine programmer.',
  type: 'service',
};
const TV2Premium: ChannelOrService = {
  title: 'TV 2 Play Premium',
  logoName: 'Tv2PlayV3',
  description: 'All sport fra TV 2 Play',
  type: 'service',
};
const TV2DisneyPlus: ChannelOrService = {
  title: 'Disney+',
  logoName: 'DisneyPlus',
  description: 'Strømmetjenesten Disney+',
  type: 'service',
};

const TV2PlayUtenReklame: ChannelOrService = {
  title: 'TV 2 Play uten reklame',
  logoName: 'Tv2PlayV3',
  description: 'Reklamefri strømming og 25 nedlastninger.',
  type: 'service',
};

const ViaplayBasic: ChannelOrService = {
  title: 'Viaplay Film & Serier',
  logoName: 'Viaplay',
  description: 'Over 300 premierefilmer, serier og konstant nytt innhold.',
  type: 'service',
};
const ViaplayPremium: ChannelOrService = {
  title: 'Viaplay Total',
  logoName: 'Viaplay',
  description: 'All sport fra Viaplay',
  type: 'service',
};

// ingested services
const SkyShowtime: ChannelOrService = {
  title: 'SkyShowtime',
  logoName: 'SkyShowtime',
  description: 'Et gigantisk bibliotek av underholdning, filmer og serier.',
  type: 'service',
};
const NordicFilmPlus: ChannelOrService = {
  title: 'Nordisk Film+',
  logoName: 'NordiskFilm+',
  description: 'Se de beste filmene fra Norge kombinert med internasjonale titler.',
  type: 'service',
};
const BBCNordicPlus: ChannelOrService = {
  title: 'BBC Nordic+',
  logoName: 'BBCNordicPlus',
  description: 'Strømmetjeneste fra BBC med skreddersydd innhold for det nordiske markedet.',
  type: 'service',
};

export const CHANNELS = {
  StrimChannels: StrimMindreChannels,
  LittChannels,
  MyeChannels,
  MestChannels,
  SportChannels,
  MaxSportChannels,
  MerUnderholdningChannels,
};

export const SERVICES = {
  // Ingested services
  SkyShowtime,
  NordicFilmPlus,
  BBCNordicPlus,
  // Max products
  MaxBasic,
  MaxStandard,
  MaxPremium,
  MaxSport,
  // TV2 products
  TV2Basic,
  TV2Standard,
  TV2Premium,
  TV2DisneyPlus,
  TV2PlayUtenReklame,
  // Viaplay products
  ViaplayBasic,
  ViaplayPremium,
  // Group of services included in Mye & Mest
  BASE_SERVICES: [SkyShowtime, NordicFilmPlus, MaxBasic, ViaplayBasic],
};
