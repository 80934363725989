import { ReactComponent as AppJumpSvg } from './svg/app-jump.svg';
import { ReactComponent as ClockSvg } from './svg/clock.svg';
import { ReactComponent as CloseSvg } from './svg/close.svg';
import { ReactComponent as ExpiredSvg } from './svg/expired.svg';
import { ReactComponent as PadlockSvg } from './svg/padlock.svg';
import { ReactComponent as TvSeriesSvg } from './svg/tv-series.svg';
import { ReactComponent as UserSvg } from './svg/user.svg';
export { ReactComponent as AddToListSvg } from './svg/add-to-list.svg';
export { ReactComponent as AddedToListSvg } from './svg/added-to-list.svg';
export { ReactComponent as ShareLinkSvg } from './svg/share-link.svg';
import { ReactComponent as AlertCircleSvg } from './svg/alert-circle.svg';
import { ReactComponent as Cart } from './svg/cart.svg';
import { ReactComponent as CelebrationSvg } from './svg/celeb.svg';
import { ReactComponent as CheckFilledCircleSvg } from './svg/check-filled-circle.svg';
import { ReactComponent as ChevronDownLightSvg } from './svg/chevron-down-light.svg';
import { ReactComponent as InfoCircle } from './svg/info.svg';
import { ReactComponent as SortingSvg } from './svg/sorting.svg';
import { ReactComponent as ThumbsUpFilledSvg } from './svg/thumbs-up-filled.svg';
import { ReactComponent as TrailerSvg } from './svg/trailer.svg';
import { ReactComponent as TvodSvg } from './svg/tvod.svg';
import { svgToIconElement } from './svgToIconElement';

export { ReactComponent as FacebookSvg } from './svg/facebook.svg';
export { ReactComponent as InstagramSvg } from './svg/instagram.svg';
export { ReactComponent as SnowflakeSvg } from './svg/snowflake.svg';
export { ReactComponent as SportSvg } from './svg/sport.svg';
export { ReactComponent as ThumbsDownSvg } from './svg/thumbs-down.svg';
export { ReactComponent as ThumbsDownFilledSvg } from './svg/thumbs-down-filled.svg';
export { ReactComponent as ThumbsUpSvg } from './svg/thumbs-up.svg';
export { ReactComponent as ThumbsUpFilledSvg } from './svg/thumbs-up-filled.svg';

export const AppJumpIcon = svgToIconElement(AppJumpSvg);
export const CartIcon = svgToIconElement(Cart);
export const CloseIcon = svgToIconElement(CloseSvg);
export const ClockIcon = svgToIconElement(ClockSvg);
export const ExpiredIcon = svgToIconElement(ExpiredSvg);
export const PadlockIcon = svgToIconElement(PadlockSvg);
export const TvSeriesIcon = svgToIconElement(TvSeriesSvg);
export const UserIcon = svgToIconElement(UserSvg);
export const ChevronDownLightIcon = svgToIconElement(ChevronDownLightSvg);
export const SortingIcon = svgToIconElement(SortingSvg);
export const InfoCircleIcon = svgToIconElement(InfoCircle);
export const ThumbsUpFilled = svgToIconElement(ThumbsUpFilledSvg);
export const TrailerIcon = svgToIconElement(TrailerSvg);
export const TvodIcon = svgToIconElement(TvodSvg);
export const AlertCircleIcon = svgToIconElement(AlertCircleSvg);
export const CelebrationIcon = svgToIconElement(CelebrationSvg);
export const CheckFilledCircle = svgToIconElement(CheckFilledCircleSvg);

import styles from './icons.module.css';
export const animateBounceClass = styles.animateBounce;
